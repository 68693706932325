import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { gsap } from "../plugins/gsap"
import { ScrollTrigger } from "../plugins/gsap/ScrollTrigger"

import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroComposerInstalled from "../components/hero-composer-installed"

gsap.registerPlugin(ScrollTrigger)

const ComposerInstalled = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageComposerInstalled {
          title
          description
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 70) {
              src
            }
          }
        }
      }
    `
  )

  const title = data?.title || "";
  const description = data?.description || "";
  const thumbnail = data?.featuredImage?.resize?.src || "";

  return (
    <Layout
      location={location}
      hero={<HeroComposerInstalled />}
      hideFooter
      hideHeader
    >
      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />
    </Layout>
  )
}

export default ComposerInstalled
