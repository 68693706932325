import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import TextGradient from "./ui/text-gradient"
import Logo from "../svg/logo.svg";

import poster from "../images/open-devtools.png"
import video from "../images/open-devtools.mp4"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <h1 className="text-white xl:text-6xl">{children}</h1>
      )
    },
  },
}

const HeroComposerInstalled = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageComposerInstalled {
          heroContent {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
        }
      }
    `
  )

  const content = data?.heroContent || "";

  return (
    <div className="bg-blue-royal composer-installed">
      <a href="/">
        <Logo className="w-auto h-7" />
      </a>
      <div className="page-content">
        <div>
          {content && renderRichText(content, options)}
        </div>

        <video
          style={{
            maxHeight: 400,
            justifySelf: 'center'
          }}
          preload="auto"
          poster={poster}
          loop
          autoPlay
          muted>
          <source src={video} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>

    </div>
  );
}

export default HeroComposerInstalled
